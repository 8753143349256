/**
 * https://www.freecodecamp.org/news/javascript-debounce-example/
 */
export default (func, timeout = 100) => {
    let timer;

    return (...args) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    }
}
