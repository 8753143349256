export default () => {
    const prodVariations = document.querySelectorAll('select.product-variations');
    if (prodVariations !== null && prodVariations.length > 0) {
        for (let i = 0; i < prodVariations.length; i++) {
            const prodVariation = prodVariations[i];
            if (prodVariation.getAttribute('data-event-change-added') === 'false') {
                prodVariation.setAttribute('data-event-change-added', 'true');

                prodVariation.addEventListener('change', () => {
                    const addToCart = prodVariation.nextElementSibling;
                    addToCart.setAttribute('href', `?add-to-cart=${prodVariation.value}`);
                    addToCart.setAttribute('data-product_id', prodVariation.value);
                    addToCart.classList.remove('added');
                    addToCart.classList.remove('opacity-50');
                    addToCart.classList.add('ajax_add_to_cart');
                    
                    const goToCart = addToCart.nextElementSibling;

                    if (goToCart !== null) {
                        goToCart.remove();
                    }
                });
            }
        }
    }
};
