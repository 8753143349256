import '../modules/scrollbarWidth';
import helpForm from '../modules/helpForm';
import gAcfMap from '../modules/gAcfMap';
import unfoldingContent from '../modules/unfoldingContent';

export default () => {
    const siteHeaderEl = document.querySelector('.site-header');
    const headerNavEl = siteHeaderEl.querySelector('.site-header__nav');

    // Toggla menyn
    $('.site-header__nav-toggle').click(() => {
        document.body.classList.toggle('stop-scrolling');
        siteHeaderEl.classList.toggle('site-header--menu-open');

        const hidden = headerNavEl.getAttribute('aria-hidden');
        headerNavEl.setAttribute('aria-hidden', hidden === 'true' ? 'false' : 'true');

        // Hide help form btn and form
        const helpFormCont = document.getElementById('help-form-container');
        const helpFormBtnCont = document.getElementById('help-form-container-open-btn');
        if (helpFormCont && helpFormBtnCont && siteHeaderEl.classList.contains('site-header--menu-open')) {
            helpFormCont.classList.add('hidden');
            helpFormBtnCont.classList.add('hidden');
        } else if (helpFormCont && helpFormBtnCont) {
            helpFormBtnCont.classList.remove('hidden');
        }
    });

    // Toggla sökformuläret i sidhuvudet
    const toggleSiteHeaderSearch = document.querySelector('.toggle-site-header-search');
    const siteHeaderSearch = document.querySelector('.site-header__search');
    if (toggleSiteHeaderSearch && siteHeaderSearch) {
        toggleSiteHeaderSearch.addEventListener('click', () => {
            siteHeaderSearch.classList.toggle('hidden');
        });
    }

    // Submenu titel
    function setSubMenuTitles() {
        const topLevels = document.querySelectorAll('#main-nav > ul > li > a');

        if (topLevels && topLevels.length > 0) {
            topLevels.forEach((topLevel) => {
                const subMenuTitle = topLevel.parentNode.querySelector('.sub-menu-container .active-sub-menu-title');
                if (subMenuTitle) subMenuTitle.textContent = topLevel.textContent;
            });
        }
    }

    setSubMenuTitles();

    // Räkna ut maxhöjd för submeny och mobilmeny
    function calcHeaderSubMenuHeight() {
        let height = 0;

        const wpAdminBar = document.getElementById('wpadminbar');
        if (wpAdminBar && wpAdminBar.clientHeight > 0) {
            height += wpAdminBar.clientHeight;
        }

        const header = document.getElementById('header');
        if (header && header.clientHeight > 0) {
            height += header.clientHeight;
        }

        document.documentElement.style.setProperty('--site-header-height', height + 'px');
    }

    calcHeaderSubMenuHeight();

    // Hjälp formuläret
    helpForm();

    // Karta
    if (document.querySelector('.acf-map') !== null) {
        gAcfMap();
    }

    // TODO: Ersätt med Lozad?
    // Ta bort data-src när bilden är laddad.
    document.querySelectorAll('img[data-src]').forEach((el) => {
        const img = el;
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = () => img.removeAttribute('data-src');
    });

    // Mobila submenyer
    const subMenuIcons = document.querySelectorAll('.menu-item a .tgr-open-submenu');

    if (subMenuIcons.length > 0) {
        subMenuIcons.forEach((subMenuIcon) => {
            subMenuIcon.addEventListener('click', (e) => {
                e.preventDefault();

                const subMenuCont = subMenuIcon.parentNode.parentNode.querySelector('.sub-menu-container');

                if (subMenuCont) {
                    const iconCont = subMenuIcon.querySelector('.icon');

                    if (iconCont) iconCont.classList.toggle('rotate');

                    subMenuCont.classList.toggle('open');
                }
            })
        });
    }

    // Utfällbart innehåll
    unfoldingContent();
};
