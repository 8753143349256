import 'whatwg-fetch';
import 'jquery-validation';
import buildBody from '../plugins/build-body';

export default () => {
    const formContainer = document.querySelector('#help-form-container');
    
    if (formContainer !== null) {
        let currentFormContainerHeight = 0;
        const formElem = document.querySelector('#form-help');
        const noticeContainer = document.querySelector('#notice-container');
        const openFormBtn = document.querySelector('#tgr-open-help-form');
        const openFormOpenBtnCont = document.querySelector('#help-form-container-open-btn');
        const closeFormBtn = document.querySelector('#tgr-close-help-form');

        openFormBtn.addEventListener('click', () => {
            formContainer.classList.remove('hidden');
            openFormOpenBtnCont.classList.add('hidden');
            currentFormContainerHeight = formContainer.clientHeight;

            if (window.innerWidth <= 1024) {
                document.querySelector('body').classList.add('stop-scrolling');
            }
        });

        closeFormBtn.addEventListener('click', () => {
            formContainer.classList.add('hidden');
            openFormOpenBtnCont.classList.remove('hidden');
            formElem.classList.remove('hidden');
            noticeContainer.classList.add('hidden');
            currentFormContainerHeight = 0;

            if (window.innerWidth <= 1024) {
                document.querySelector('body').classList.remove('stop-scrolling');
            }
        });

        async function sendHelpFormMessage(form, formSubmitBtn) {
            const options = {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: buildBody({
                    action: 'send_help_form_message',
                    _ajax_nonce: horizon.ajaxNonce,
                    name: document.querySelector('#form-help #form-help-name').value,
                    email: document.querySelector('#form-help #form-help-email').value,
                    phone: document.querySelector('#form-help #form-help-phone').value,
                    message: document.querySelector('#form-help #form-help-message').value,
                    terms: document.querySelector('#form-help #form-help-privacy-terms').checked,
                }),
            };

            const response = await fetch(horizon.ajaxUrl, options);

            if (response.status === 200) {
                const responseJson = await response.json();

                form.reset();
                formSubmitBtn.removeAttribute('disabled');
                formSubmitBtn.textContent = 'Skicka';
                formElem.classList.add('hidden');
                noticeContainer.classList.remove('hidden');
                formContainer.style.minHeight = `${currentFormContainerHeight}px`;

                if (responseJson.status === 'success') {
                    noticeContainer.firstElementChild.textContent = 'Tack för ditt meddelande, vi återkommer inom kort.';
                } else {
                    noticeContainer.firstElementChild.textContent = 'Något gick fel, vänligen försök igen.';
                }
            }
        }

        $('#form-help').validate({
            rules: {
                form_help_name: {
                    required: true,
                },
                form_help_email: {
                    required: true,
                    email: true,
                },
                form_help_phone: {
                    required: true,
                },
                form_help_message: {
                    required: true,
                },
                form_help_privacy_terms: {
                    required: true,
                },
            },
            messages: {
                form_help_name: 'Vänligen fyll i namn',
                form_help_email: 'Vänligen fyll i e-postadress',
                form_help_phone: 'Vänligen fyll i telefonnummer',
                form_help_message: 'Vänligen fyll i meddelande',
                form_help_privacy_terms: 'Vänligen acceptera integritetspolicyn',
            },
            submitHandler(form, event) {
                event.preventDefault();

                const formSubmitBtn = form.querySelector('button');
                formSubmitBtn.setAttribute('disabled', 'disabled');
                formSubmitBtn.textContent = 'Skickar..';

                sendHelpFormMessage(form, formSubmitBtn);
            },
        });

        window.addEventListener('scroll', () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                openFormOpenBtnCont.classList.add('bottom-48');
                openFormOpenBtnCont.classList.add('lg:bottom-32');
                formContainer.classList.add('lg:bottom-32');
            } else {
                openFormOpenBtnCont.classList.remove('bottom-48');
                openFormOpenBtnCont.classList.remove('lg:bottom-32');
                formContainer.classList.remove('lg:bottom-32');
            }
        });
    }
};
