import 'whatwg-fetch';
import buildBody from '../plugins/build-body';
/**
 * Gets the current cart quantity (number of items in cart)
 */
export default async () => {
    const options = {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: buildBody({
            action: 'woo_get_current_cart_quantity',
            _ajax_nonce: horizon.ajaxNonce,
        }),
    };
    
    const response = await fetch(horizon.ajaxUrl, options);
    
    if (response.status === 200) {
        const quantity = await response.json();
        const span = document.getElementById('current-cart-quantity');
        
        if (span !== null) {
            span.textContent = quantity;
        }
    }
};
