/**
 * https://destroytoday.com/blog/100vw-and-the-horizontal-overflow-you-probably-didnt-know-about
 */
document.addEventListener('DOMContentLoaded', () => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);

    const containers = document.querySelectorAll('.alignfully');

    if (containers !== null && containers.length > 0) {
        for (let i = 0; i < containers.length; i++) {
        containers[i].style.opacity = '100%';
        }
    }
});
  