import debounce from './debounce';
import getCurrentCartQuantity from './getCurrentCartQuantity';

export default () => {
    /**
     * Debounced function getCurrentCartQuantity to 250 ms
     */
     const debounceGCCQ = debounce(() => {
        getCurrentCartQuantity();
    }, 250);

    const ajaxAddToCartBtns = document.querySelectorAll('.add_to_cart_button');

    if (ajaxAddToCartBtns !== null && ajaxAddToCartBtns.length > 0) {
        for (let i = 0; i < ajaxAddToCartBtns.length; i++) {
            const btn = ajaxAddToCartBtns[i];

            btn.addEventListener('click', () => {
                console.log('click');
                debounceGCCQ();
            });
        }
    }
};
