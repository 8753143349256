import { dom, library, config } from '@fortawesome/fontawesome-svg-core';


import {
    faTimes as solidTimes,
    faUserHeadset,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faCheck,
    faTimes,
    faInfoCircle,
    faExclamationCircle,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faShoppingCart,
    faUser,
    faSearch,
    faFilter,
    faTrash,
    faPlus,
    faMinus,
} from '@fortawesome/pro-regular-svg-icons';

// import {} from '@fortawesome/pro-light-svg-icons';

/*
import {
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
*/
config.autoReplaceSvg = true;
config.observeMutations = true;
// config.searchPseudoElements = true;

library.add(
    faCheck,
    faTimes,
    solidTimes,
    faInfoCircle,
    faExclamationCircle,
    faUserHeadset,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faShoppingCart,
    faUser,
    faSearch,
    faFilter,
    faTrash,
    faPlus,
    faMinus,
);

dom.watch();
