import 'jquery-validation';

export default () => {
    $('#').validate({
        rules: {
            name: {
                required: true,
            },
            phone: {
                required: true,
            },
            email: {
                required: true,
                email: true,
            },
            message: {
                required: true,
            },
        },
        messages: {
            name: '',
            phone: '',
            email: '',
            message: '',
        },
        submitHandler(form) {
            // Saker du vill göra här
            // submitHandler kan tas bort helt ifall extra logik inte behövs

            form.submit();
        },
    });
};
