export default () => {
    const unfoldableContents = document.querySelectorAll('.unfolding-content-container .unfolding-content');

    if (unfoldableContents === null || unfoldableContents.length === 0) {
        return;
    }

    unfoldableContents.forEach((unfoldableContent) => {
        const btn = unfoldableContent.querySelector('button');

        if (btn !== null) {
            btn.addEventListener('click', () => {
                const content = unfoldableContent.querySelector('div');
                const plusIcon = btn.querySelector('.plus-icon');
                const minusIcon = btn.querySelector('.minus-icon');

                if (content !== null) {
                    content.classList.toggle('hidden');
                    plusIcon.classList.toggle('hidden');
                    minusIcon.classList.toggle('hidden');
                }
            });
        }

    });
};
