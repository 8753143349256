function buildBody(input) {
    const euc = encodeURIComponent;
    const data = [];
    for (let name in input) {
        data.push(`${euc(name)}=${euc(input[name])}`);
    }

    return data.join('&').replace('%20', '+');
}

export default buildBody;
